import React , {useState , useEffect} from "react";
import axios from "axios";

const EventsBar = (parms)=>{

    const [news , setNews] = useState('! اهلا وسهلا بمتجرنا , نتمنى لكم يوما سعيدا')

    useEffect(()=>{
        const getNews = async()=>{
            const news = await axios.get(`/api/news/getNews`);
            setNews(news.data);
        }

        setInterval(()=>{
            getNews();
        } , 10000)
    } , [parms])

    
    return(
        <div className="flex justify-center items-center py-10 2md:py-40">
            <h1 className="w-[70vw] p-4 rounded-2xl border-[1px] text-[#FF8450] text-center border-solid border-[#FF8450]">
                {news}
            </h1>
        </div>
    )
}

export default EventsBar;