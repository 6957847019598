import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Nav from "../components/Nav";
import Categories from "../components/Catagories";
import Footer from "../components/Footer";
import ProductsCardsHolder from "../components/ProductsCardsHolder";
//import LastNews from "../components/LastNews";
import axios from 'axios';
import DeleiveryState from "../components/DeleiveryState";
import EventImageSection from "../components/EventImageSection";
import EventsBar from "../components/EventsBar";
import SearchBar from "../components/SearchBar";
import Offers from "../components/Offers";


const MainPage = (parms)=>{

  const [categories , setCategories] = useState([]);
  const [products , setProducts] = useState([]);
  const [mainCards , setMainCards] = useState([]);
    useEffect(()=>{
      const getCategories = async()=>{
        // const response = await axios.get(`${parms.url}categories/`);
        const response = await axios.get(`/api/categories/`);

        const categories = response.data;
        setCategories(categories);
      }
      getCategories();

      const getProducts = async()=>{
        // const response = await axios.get(`${parms.url}products/firstSixProducts`);
        const response = await axios.get(`/api/products/firstSixProducts`);

        const products = response.data;
        setProducts(products);
      }

      getProducts();

      const getMainCards = async()=>{
        const response = await axios.get('/api/mainCards');
        setMainCards(response.data);
      }

      getMainCards();

    } , [parms])




    if(categories.length > 0 && products.length > 0){
    return(
        <div>
            <header className="">
                <nav><Nav /></nav>
                <Header />
            </header>

            <section className="pt-[14vh] pb-[14vh] text-yellow-400 bg-white"><DeleiveryState url={parms.url}  /></section>
            <section><EventImageSection pubkey={parms.pubkey}/></section>
            <section><EventsBar/></section>

            <section><Categories url={parms.url} categoriesList={categories} /></section>
            <section><Offers slides={mainCards}/></section>
            <section><SearchBar/></section>

          {categories.map((category , i)=>{
            const a = products[i]
            return <section><ProductsCardsHolder productsList={a} url={parms.url} categories={categories}/></section>
          })}

            <footer>
                <div><Footer/></div>
            </footer>
        </div>
    );
    }else{
      return <div>Loading ...</div>
    }
}

export default MainPage