import axios from "axios";
import React, { useState } from "react";
import Cookies from "js-cookie";
const ChangeDeleivaryStatus = (parms)=>{

    const [response , setResponse] = useState('')

    const clickHandler = async(e)=>{
        e.preventDefault();
        const response = await axios.get(`/api/deleivary/change` , {
            headers: {
                "Authorization":`Bearer ${Cookies.get(parms.pubkey)}`
            }
        });

        setResponse(response.data);    
    }

    return(
        <div className="w-full flex justify-center items-center flex-col m-5">
            <button className="p-1 text-white bg-indigo-500 rounded-lg" onClick={e=>clickHandler(e)}>تغيير حالة التوصيل</button>
            <span className="block m-3">{response}</span>
        </div>
    )
}

export default ChangeDeleivaryStatus;