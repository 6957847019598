import axios from "axios";
import React, { useState } from "react";
import Cookies from "js-cookie";
const AddMainCardField = (parms)=>{

    const [imageLink , setImageLink] = useState('');
    const [title , setTitle] = useState('');
    const [description , setDescription] = useState('');

    const [response , setResponse] = useState('');

    const addMainCard = async(e)=>{

        // send main card to the server

        const response = await axios.post(`/api/mainCards/addMainCard` , {imageLink , title , description} , {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded' ,
                "Authorization":`Bearer ${Cookies.get(parms.pubkey)}`
            }
        })

        setResponse(response.data);
    
    }

    return <div className="w-full">
        <div className="p-6 w-full text-center">
            <div>
                <label>Image Url</label>
                <input type="url" onChange={e=>setImageLink(e.target.value)} className="m-2 p-1 border border-1px border-black border-solid"/>
            </div>
            <div>
                <label>Title</label>
                <input type="text" onChange={e=>setTitle(e.target.value)} className="m-2 p-1 border border-1px border-black border-solid"/>
            </div>
            <div>
                <label>Description</label>
                <input type="text" onChange={e=>setDescription(e.target.value)} className="m-2 p-1 border border-1px border-black border-solid"/>
            </div>
            <div>
                <button type="submit" onClick={e=>addMainCard(e)} className="w-20 h-10 text-white rounded-lg bg-indigo-500 hover:bg-indigo-400">ارسال</button>
                <span className="m-5 block">{response}</span>
            </div>
        </div>
    </div>
}

export default AddMainCardField;