import axios from "axios";
import React, { useState } from "react";

const SearchBar = ()=>{
    const [name , setName] = useState('');
    const [response , setResponse] = useState('');
    const searchProduct = async()=>{
        const response = await axios.get(`/api/products/byName/${name}`)
        if(response.data.length && response.data.length > 0){
            window.location = `/products/${response.data[0].id}`;
        }else{
            setResponse('تأكد من الإسم المدخل')
        }
    }

    return(
        <div>
            <div className="flex justify-around my-20 px-5">
                <button onClick={e=>searchProduct(e)} className="border-[1px] border-[#FF8450] text-[#FF8450] mx-3 w-20 p-1 rounded-xl">ابحث</button>
                <input placeholder="ابحث عن منتج" onChange={e=>setName(e.target.value)} className="rounded-xl w-full 2md:w-[70%] block border-[1px] border-[#FF8450] text-[#FF8450] p-1 px-3"/>
            </div>
            <div><span className="block text-[#FF8450] text-center">{response}</span></div>
        </div>

    )
}

export default SearchBar;