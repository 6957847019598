// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import axios from 'axios';


// const Categories = (parms) => {

//   const [categories , setCategories] = useState([]);

//   useEffect(()=>{
//     const getCategories = async()=>{
//       const response = await axios.get(`${parms.url}categories/`);
//       const data = response.data;
//       setCategories(data);
//     }
//     getCategories();
//   } , [parms])
//   if(categories.length !== 0){
//     return (
//       <div className="container mx-auto px-4 flex flex-col mt-5">
//         <h1 className='text-5xl mb-16 text-center text-[#DB6035]'>الاصناف</h1>
//         <div className="flex flex-wrap -mx-2">
//           {categories.map((category) => (
//             <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 px-2 mb-4">
//               <div className="relative h-36 rounded-lg overflow-hidden border-2 border-[#DB6035]">
//                   <img
//                       className="absolute inset-0 w-full h-full object-cover"
//                       src={`${parms.url}images/${category.image.name}`}
//                       alt={category.name}
//                   />
//                 <div className="absolute inset-0 bg-gray-900 bg-opacity-70"></div>
//                 <div className="absolute inset-0 p-4 flex items-center justify-center">
//                 <Link to={`/catagories/${category.name}`}><h3 className="text-white text-lg font-bold text-center text-[#DB6035]">{category.name}</h3></Link>

//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     );
//   }else{
//     return <div>Loading ...</div>
//   }
// };

// export default Categories;

import React , {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Categories = ({url , categoriesList})=>{
    const [categories , setCategories] = useState([])
    
    useEffect(()=>{
        const getCategories = async()=>{
            // const categories = await axios.get(`${url}categories/`)
            const categories = await axios.get(`/api/categories/`)

            // console.log(categories);
            setCategories(categories.data);
        }
        getCategories();
    } , [url , categoriesList])

    if(categories.length !== 0){
        return(
            
            <div className="grid grid-cols-2 md:grid-cols-4 p-8 md:p-10 2md:p-20 text-center gap-[5vw] w-screen h-full">
                
                {categories.map((category , i)=>{
                        return(
                             <Link to={`/catagories/${category.name}`}>
                                <div className="text-xl md:text-2xl flex justify-center items-center gap-5 flex-col h-full w-full text-[#FF8450]">
                                    <img 
                                        src={`${url}images/${category.image.name}`}
                                        className="w-[100%] h-[100%] block rounded-full"
                                    />
                                    <h2 className="text-center">{category.name}</h2>
                                </div>
                        </Link>
                        )
                    })}
                

            </div>
        )
    }else{
        return(
            <h1>Loading...</h1>
        )
    }
}

export default Categories;