import React , {useState , useEffect} from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const AdminProductPanel = (parms)=>{

    const [products , setProducts] = useState([])
    const [nameResponse , setNameResponse] = useState('');
    const [priceResponse , setPriceResponse] = useState('');
    const [stockStatusResponse , setStockStatusResponse] = useState('');

    const [name , setName] = useState('');
    const [price , setPrice] = useState(0);
    const [stockStatus , setStockStatus] = useState(false);

    const [productId , setProductId] = useState(''); 

    const [page , setPage] = useState(1);

    const changeName = async(e , id)=>{
        // const response = await axios.get(`${parms.url}products/updateName/${id}/${name}` , {
        //     headers: {
        //         "Authorization":`Bearer ${Cookies.get(parms.pubkey)}`
        //     }
        // });

        const response = await axios.get(`/api/products/updateName/${id}/${name}` , {
            headers: {
                "Authorization":`Bearer ${Cookies.get(parms.pubkey)}`
            }
        });

        setNameResponse(response.data);
        console.log(response);
    }

    const getProductById = async(e)=>{
        const id = productId;
        if(id.length!==0){
            // const response = await axios.get(`${parms.url}products/byId/${id}`);
            const response = await axios.get(`/api/products/byId/${id}`);

            setProducts([response.data]);
        }else{
            // const response = await axios.get(`${parms.url}products`);
            const response = await axios.get(`/api/products`);

            setProducts(response.data);
        }

    }

    const changePrice = async(e , id)=>{
        // const response = await axios.get(`${parms.url}products/updatePrice/${id}/${price}` , {
        //     headers: {
        //         "Authorization":`Bearer ${Cookies.get(parms.pubkey)}`
        //     }
        // });

        const response = await axios.get(`/api/products/updatePrice/${id}/${price}` , {
            headers: {
                "Authorization":`Bearer ${Cookies.get(parms.pubkey)}`
            }
        });

        setPriceResponse(response.data);
    }

    const changeStockStatus = async(e , id , outOfStock)=>{
            // const response = await axios.get(`${parms.url}products/updateStockStatus/${id}` , {
            //     headers: {
            //         "Authorization":`Bearer ${Cookies.get(parms.pubkey)}`
            //     }
            // }).then((val)=>{
            //     if('Stock status updated successfully'){
            //         const status = document.getElementById(`stat${id}`);
            //         const oosB = document.getElementById(`oos${id}`);
            //         if(oosB.classList[1] === 'bg-red-500'){
            //             oosB.classList.replace('bg-red-500' , 'bg-green-500');
            //             status.innerHTML = 'Out of stock: no'
            //         }else{
            //             oosB.classList.replace('bg-green-500' , 'bg-red-500');
            //             status.innerHTML = 'Out of stock: yes'
            //         }
            //         setStockStatusResponse(val.data);
            //     }
            // })


            const response = await axios.get(`/api/products/updateStockStatus/${id}` , {
                headers: {
                    "Authorization":`Bearer ${Cookies.get(parms.pubkey)}`
                }
            }).then((val)=>{
                if('Stock status updated successfully'){
                    const status = document.getElementById(`stat${id}`);
                    const oosB = document.getElementById(`oos${id}`);
                    if(oosB.classList[1] === 'bg-red-500'){
                        oosB.classList.replace('bg-red-500' , 'bg-green-500');
                        status.innerHTML = 'Out of stock: no'
                    }else{
                        oosB.classList.replace('bg-green-500' , 'bg-red-500');
                        status.innerHTML = 'Out of stock: yes'
                    }
                    setStockStatusResponse(val.data);
                }
            })

    }

    useEffect(()=>{
        const getProducts = async()=>{
            // const response = await axios.get(`${parms.url}products`);
            const response = await axios.get(`/api/products`);

            setProducts(response.data);
        }
        getProducts();
    } , [])

    // const products = [
    //     {
    //         id:1 ,
    //         name: 'banana' ,
    //         price: 5 ,
    //         outOfStock: false ,
    //     } ,
    //     {
    //         id:2 ,
    //         name: 'banana' ,
    //         price: 5 ,
    //         outOfStock: false ,
    //     } ,
    //     {
    //         id:3 ,
    //         name: 'banana' ,
    //         price: 5 ,
    //         outOfStock: false ,
    //     }
    // ]
    return(
        <div className='flex flex-col justify-center items-center'>
            <h1 className='text-4xl my-20'>Products</h1>
            <div className='flex'>
                <button className='mx-3 bg-purple-500 p-2 rounded-lg text-white hover:bg-purple-400' onClick={e=>getProductById(e)}>Submit</button>
                <input className='border-2 border-black rounded-lg p-1' onChange={e=>setProductId(e.target.value)} type='string' />
            </div>
            <ul id='products' className='max-w-[70vw]'>
                {products.map((product , i)=>{
                    return(
                    <li className='flex'>
                        <div className='flex m-10 bg-gray-100 rounded-lg p-5'>
                            <div className='mr-5'>
                                <img
                                    src={`${parms.url}images/${product.image.name}`}
                                    className='h-[100px]'
                                 />
                            </div>
                            <div>
                                <h1>ID: {product.id}</h1>
                                <h1>Name: {product.name}</h1>
                                <h1>Category: {product.catagory}</h1>
                                <span className='block'>Price: {product.price}</span>
                                <span id={`stat${product.id}`} className='block'>Out of stock: {product.outOfStock ? 'yes' : 'no'}</span>
                            </div>
                        </div>

                        <div className='m-20 p-5 bg-gray-100'>
                            <div className='m-2'>
                                <button onClick={(e)=>changeName(e , product.id)} className='m-2 bg-indigo-600 p-2 text-white rounded-lg'>Change name</button>
                                <input onChange={e=>setName(e.target.value)} className='border-2 border-black rounded-md' type='text' />
                            </div>
                            <div className='m-2'>
                                <button onClick={(e)=>changePrice(e , product.id)} className='m-2 bg-indigo-600 p-2 text-white rounded-lg'>Change price</button>
                                <input onChange={e=>setPrice(e.target.value)} className='border-2 border-black rounded-md' type='text' />
                            </div>
                            <div className='m-2'>
                                <button id={`oos${product.id}`} onClick={e=>changeStockStatus(e , product.id , product.outOfStock)} className={`m-2 ${product.outOfStock ? 'bg-red-500' : 'bg-green-500'} p-2 text-white rounded-lg`}>Out of stock</button>
                            </div>
                        </div>

                    </li>
                    )
                })}

            </ul>
        </div>
    )
}

export default AdminProductPanel;