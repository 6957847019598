// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
// import { Link } from 'react-router-dom';

// const Footer = () => {
//   return (
//     <footer className="bg-[#385B64] text-white">
//       <div className="container mx-auto flex justify-between items-center py-8 px-4 sm:px-6 lg:px-8">
//         <div className="flex flex-col flex-wrap justify-center items-center">
//           <h3 className="text-xl font-semibold mb-2">تابعنا على</h3>
//           <div className="flex flex-col">
//             <Link to="https://www.facebook.com/profile.php?id=100088087514726" target='_blank' className="text-gray-400 hover:text-gray-100">
//               <span className="sr-only">Facebook</span>
//               <FontAwesomeIcon icon={faFacebookF} size="2x" />
//             </Link>
//             {/* <Link to="#" className="text-gray-400 hover:text-gray-100">
//               <span className="sr-only">Twitter</span>
//               <FontAwesomeIcon icon={faTwitter} size="2x" />
//             </Link>
//             <Link to="#" className="text-gray-400 hover:text-gray-100">
//               <span className="sr-only">Instagram</span>
//               <FontAwesomeIcon icon={faInstagram} size="2x" />
//             </Link> */}

//           </div>
//         </div>
//         <div className="flex flex-col flex-wrap justify-start items-center">
//           <h3 className="text-xl font-semibold mb-2">سياساتنا</h3>
//           <nav className="flex flex-col flex-wrap justify-center items-center">
//             <Link to="/privacy-policy" className="text-gray-400 hover:text-gray-100">
//               Privacy Policy
//             </Link>
//             <Link to="/terms-of-service" className="text-gray-400 hover:text-gray-100">
//               Terms of Service
//             </Link>
//             <Link to="/refund-policy" className="text-gray-400 hover:text-gray-100">
//               Refund Policy
//             </Link>
//           </nav>
//         </div>
//         <div className="flex flex-col flex-wrap justify-center items-center">
//           <h3 className="text-xl font-semibold mb-2">تواصل معنا</h3>
//           <a href="mailto:support@example.com" className="text-gray-400 hover:text-gray-100">
//             support@example.com
//           </a>
//           <p className="text-gray-400 hover:text-gray-100 mt-2">
//             +972 599 999 999
//           </p>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;


import React from "react";
import { BsFacebook } from 'react-icons/bs';
import barCode from '../images/facebook-barcode.jpg';
const Footer = ()=>{
    return(
        <div className="w-screen bg-[#FF8450]">
            <div className="grid grid-cols-2 text-center mr-3 p-3">
                <div>
                    <h3 className="text-white mt-5 text-sm md:text-xl">
                        تواصل معنا
                    </h3>
                    <ul className="flex flex-col justify-around items-center py-5 h-full">
                        <li className="text-white text-3xl"><BsFacebook/></li>
                        <li className="text-white text-sm p-3">+97299999999</li>
                    </ul>

                </div>
                <div>
                    
                    <h3 className="text-white mt-5 text-sm md:text-xl">
                        سياساتنا
                    </h3>
                    <ul className="flex flex-col justify-around items-center py-5 h-full">
                        <li className="text-white text-3xl"><BsFacebook/></li>
                        <li className="text-white text-sm p-3 ">+97299999999</li>
                    </ul>
                </div>

            </div>
            <div className="flex flex-col justify-center items-center h-1/2">
                <span className="block text-white m-5">Facebook Page</span>
                <img 
                    src={barCode}
                    alt="Facebook page barcode"
                    className="w-40 block"
                />
            </div>
        </div>
    )
}

export default Footer;