// import React from "react";

// const ProductsCardsHolder = ({ categoryName, productList , url , isOdd , mainPage}) => {
//   const imagesLink = `${url}images/`;
//   const products = productList.reverse();

//   const handleImageLoad = (e) => {
//     e.target.style.opacity = 1;
//   };

//   const lineThrough = {
//     textDecoration: "line-through",
//   };

//   if(isOdd){
//     return (
//       <div className="container mx-auto py-10 px-10 bg-gray-100">
//         <h2 className="text-4xl font-bold mb-5 text-[#DB6035] text-center">
//           {categoryName}
//         </h2>
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
//           {products.map((product) => {
//           return(
            
//             <div key={product.id} className="bg-white rounded-lg shadow-md border-4 border-[#DB6035] p-2">
//               {product.image && (
//                 <img
//                   src={`${imagesLink}${product.image.name}`}
//                   alt={product.name}
//                   className="h-48 w-full object-cover rounded-t-lg"
//                   onLoad={handleImageLoad}
//                 />
//               )}
//               <div className="p-4">
//                 <h3 className="font-bold text-lg mb-2">{product.name}</h3>
//                 <p className="text-gray-700 text-base mb-4">{product.discount > 0 ? (
//       <>
//         {(product.price - product.discount * product.price)}
//         <span className="ml-2 text-gray-600 text-sm" style={lineThrough}>
//           {(product.price)}
//         </span>
//       </>
//     ) : (
//     (product.price)
//     )}</p>
//                 <button
//                   onClick={(e) =>
//                     (window.location.href = `/products/${product.id}`)
//                   }
//                   className="bg-[#DB6035] hover:bg-yellow-400 text-white font-bold py-2 px-4 rounded"
//                 >
//                   شراء الان
//                 </button>
//               </div>
//             </div>
//           )})}
//         </div>
//       </div>
//     );
//   }else{
//     return (
//       <div className="container mx-auto py-10 px-10 bg-white">
//         <h2 className="text-4xl font-bold mb-5 text-[#DB6035] text-center">
//           {categoryName}
//         </h2>
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
//           {products.map((product , i) => {
//             if(i <= 5 || !mainPage){
//           return(
            
//             <div key={product.id} className="bg-white rounded-lg shadow-md border-4 border-[#DB6035] p-2">
//               {product.image && (
//                 <img
//                   src={`${imagesLink}${product.image.name}`}
//                   alt={product.name}
//                   className="h-48 w-full object-cover rounded-t-lg"
//                   onLoad={handleImageLoad}
//                 />
//               )}
//               <div className="p-4">
//                 <h3 className="font-bold text-lg mb-2">{product.name}</h3>
//                 <p className="text-gray-700 text-base mb-4">{product.discount > 0 ? (
//       <>
//         {(product.price - product.discount * product.price)}
//         <span className="ml-2 text-gray-600 text-sm" style={lineThrough}>
//           {(product.price)}
//         </span>
//       </>
//     ) : (
//     (product.price)
//     )}</p>
//                 <button
//                   onClick={(e) =>
//                     (window.location.href = `/products/${product.id}`)
//                   }
//                   className="bg-[#DB6035] hover:bg-yellow-400 text-white font-bold py-2 px-4 rounded"
//                 >
//                   شراء الان
//                 </button>
//               </div>
//             </div>
//           )
//             }
// })}
//         </div>
//       </div>
//     );
//   }
  
//   }

  
// export default ProductsCardsHolder;

import React from "react";

const ProductCardsHolder = ({url , productsList })=>{
    if(productsList.length !== 0){
    return(
        <div className="text-center my-10">
                
                         <h2 className="text-[#FF8450] m-5 text-3xl">{productsList[0].catagory}</h2>
                         <div>
                            <div className="grid grid-cols-2 w-[98vw] p-3 pr-9 gap-3 md:grid-cols-3 md:p-9 md:pr-15 md:gap-8 2md:px-28">
                            {productsList.map((product , i)=>{
                                return <div className="w-full h-full bg-[#FF8450] rounded-2xl text-center m-3 2md:w-[80%]">
                                    <img 
                                        src={`${url}images/${product.image.name}`}
                                        className="w-full h-1/2 rounded-t-2xl block"
                                    />
                                    <h4 className="text-white text-sm">
                                        {product.name}
                                    </h4>
                                    <button onClick={(e)=>{window.location.href = `/products/${product.id}`}} className="bg-white w-1/2 h-[15%] rounded-md my-2 text-[#FF8450]">
                                        شراء
                                    </button>
                                    <span className="block text-white text-sm">
                                        {product.price} شيكل
                                    </span>
                                </div>
                            })}
                        </div>
                    </div>

            
        </div>
    )
    }else{
        console.log('nothing');
        return <div>Loading ...</div>
    }
}

export default ProductCardsHolder;