import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoBicycleOutline } from "react-icons/io5";

const DeleiveryState = ()=>{

    const [status , setStatus] = useState(true)
    useEffect(()=>{
        const getDeleivaryStatus = async()=>{
            const response = await axios.get('/api/deleivary/get');
            setStatus(response.data);
        }

        getDeleivaryStatus();
    } , [])

    if(status){
        return(
            <div className="mt-40 flex flex-col md:flex-row justify-end items-center gap-10 md:gap-20 p-20">
                <div className="text-center text-6xl text-green-500"><IoBicycleOutline /></div>
                <h1 className="text-[#FF8450] text-2xl text-right">التوصيل حاليا متاح</h1>
            </div>
        )
    }else{
        return(
            <div className="mt-40 flex flex-col md:flex-row justify-end items-center gap-10 md:gap-20 p-20">
                <div className="text-center text-6xl text-red-500"><IoBicycleOutline /></div>
                <h1 className="text-[#FF8450] text-2xl text-right">التوصيل حاليا غير متاح</h1>
            </div>
        )
    }

}

export default DeleiveryState;