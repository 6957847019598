import React from "react";
const Header = () => {

  const img1 = require("../images/hero-image.png")

  return (
    <div className="relative">
      <div className="bg-[#FF8450] h-[50vh] w-[100vw]">
            <div className="absolute rounded-bl-[30px] border-b-2 border-l-2 border-dotted border-white w-[90vw] h-[63vh] left-[13vw] z-20">

            </div>
            <div className="absolute text-3xl lg:text-7xl sm:text-5xl md:text-6xl w-[60vw] sm:w-[50vw] left-[6vw] sm:left-[0.2vw] top-[6vh] sm:top-[3vh] 2md:left-[5vw] 2md:top-[10vh]  text-white z-20 z-10 text-bold text-right block ">
              سوبر ماركت اورنج
            </div>
            <div className="text-xl top-[23vh] left-[13vw] w-[40vw] sm:text-3xl md:text-4xl sm:top-[38vh] sm:left-[14vw] text-right text-white absolute z-20 font-light text-center">
              كل ما تحتاج بضغطة زر
            </div>
            <div className={`absolute z-10 right-[6vw] top-[7vh]`}>
              <img
                src={img1}
                className="block w-[50vw] h-[30vw] rounded-[5vw] cover"
              />
            </div>
      </div>
      <div className="flex absolute top-[3vh] left-[-30px]">
        <div className="bg-[#FF8450] rounded-full w-[110vw] h-[35vh] sm:w-[90vw] absolute top-[30vh] left-[-30px]"></div>
        <div className="bg-white rounded-full w-[70vw] h-[30vh] sm:h-[47vh] absolute top-[20vh] left-[80vw]"></div>
      </div>
    </div>
  );
};

export default Header;
