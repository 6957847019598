import React, { useEffect, useState } from "react";
import img1 from '../images/event-image-holder.png'
import axios from "axios";
import Cookies from "js-cookie";

const EventImageSection = (parms)=>{

    const [imageLink , setImageLink] = useState(img1);

    useEffect(()=>{
        const getImage = async()=>{
            const response = await axios.get('/api/news/getEventImage' , {
                headers: {
                    "Authorization":`Bearer ${Cookies.get(parms.pubkey)}`
                }
            });

            setImageLink(response.data);
        }

        getImage();
    } , [parms])

    return(
        <div className="flex relative flex-col justify-center items-center">
            <img
                src={imageLink}
                className="absolute top-[13vh] md:top[18vh] w-[75vw] md:w-[65vw]"
            />
            <img 
                src={img1}
                className="w-[90vw] md:w-[70vw] pt-[10vh] p-[2vh]"
            />
        </div>
    )
}

export default EventImageSection