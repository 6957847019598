// import React, { useState } from "react";
// import {Link} from 'react-router-dom'
// import { HiMenuAlt3, HiX } from "react-icons/hi";
// import Cookies from 'js-cookie';
// import logo from '../logo.png'
// const Nav = (parms) => {
//   const [showMenu, setShowMenu] = useState(false);

//   const handleToggle = () => {
//     setShowMenu(!showMenu);
//   };

//   return (
//     <nav className="flex flex-wrap items-center justify-between p-6 bg-white">
//       <div className="flex items-center flex-shrink-0 mr-6">
//         <Link to="/">
//           <img src={logo} alt="Your logo" className="h-10" />
//         </Link>      
//       </div>
//       <div className="block lg:hidden">
//         <button
//           className="flex items-center px-3 py-2 border rounded text-gray-300 border-gray-300 hover:text-gray-900 hover:border-gray-900"
//           onClick={handleToggle}
//           aria-label="Toggle navigation menu"
//         >
//           {showMenu ? <HiX className="h-6 w-6" /> : <HiMenuAlt3 className="h-6 w-6" />}
//         </button>
//       </div>
//       <div
//         className={`${
//           showMenu ? "block" : "hidden"
//         } w-full lg:flex lg:items-center lg:w-auto`}
//       >
//         <div className="text-lg lg:flex-grow">
//           <Link
//             to="/"
//             className="block mt-4 lg:inline-block lg:mt-0 text-[#DB6035] hover:text-yellow-200 mr-4"
//             onClick={handleToggle}
//           >
//             الرئيسية
//           </Link>
//           <Link
//             to="/catagories"
//             className="block mt-4 lg:inline-block lg:mt-0 text-[#DB6035] hover:text-yellow-200 mr-4"
//             onClick={handleToggle}
//           >
//             الاصناف
//           </Link>
//           <Link
//             to="/cart"
//             className="block mt-4 lg:inline-block lg:mt-0 text-[#DB6035] hover:text-yellow-200 mr-4"
//             onClick={handleToggle}
//           >
//             السلة
//           </Link>
//           {Cookies.get("id") ? <Link
//             to="/profile"
//             className="block mt-4 lg:inline-block lg:mt-0 text-[#DB6035] hover:text-yellow-200 mr-4"
//             onClick={handleToggle}
//           >
//             الحساب
//           </Link> : (<Link
//             to="/login"
//             className="block mt-4 lg:inline-block lg:mt-0 text-[#DB6035] hover:text-yellow-200 mr-4"
//             onClick={handleToggle}
//           >
//             تسجيل الدخول
//           </Link>)}
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Nav;

import React from "react";
import { FaUser , FaShoppingCart  } from 'react-icons/fa';
import { IoCloseOutline } from "react-icons/io5";
import { FiMenu } from "react-icons/fi"
import { Link } from "react-router-dom";
import { useState } from "react";
import Cookies from "js-cookie";
import logo from "../images/logo-thaer.png";
const Nav = ()=>{

    const [showMenu , setShowMenu] = useState(false);

    const navMenu = <div className="bg-[#FF8450] w-screen h-screen absolute z-40 left-0 top-0 flex justify-center items-center flex-col gap-10">
            <button className="text-white text-4xl" onClick={(e)=>setShowMenu(false)}>
                <IoCloseOutline/>
            </button>
            <Link className="text-2xl text-white" to={'/'}>الرئيسية</Link>
            <Link className="text-2xl text-white" to={'/catagories'}>الأصناف</Link>
            {Cookies.get('id') ? <Link className="text-2xl text-white" to={'/cart'}><FaShoppingCart /></Link> : <Link className="text-2xl text-white" to={'/login'}>تسجيل الدخول</Link>}
            <Link className="text-2xl text-white" to={'/profile'}><FaUser /></Link>
            
        </div>

    return(
        <div className="bg-[#FF8450] p-5">
            { showMenu ? navMenu : null}
            <div className="flex justify-between px-[7vw] mt-8">
                <div>
                    <h1 className="w-[100px] h-[100px]"><img src={logo} width={'full'} /></h1>
                </div>
                <ul className="flex w-1/2 gap-10 items-center justify-end text-white invisible 2md:visible">
                    <li className="hover:text-orange-100"><Link to={'/'}>الرئيسية</Link></li>
                    <li className="hover:text-orange-100"><Link to={'/catagories'}>الأصناف</Link></li>
                    <li>{Cookies.get('id') ? <Link className="text-2xl text-white" to={'/cart'}><FaShoppingCart /></Link> : <Link className="text-white hover:text-orange-100" to={'/login'}>تسجيل الدخول</Link>}</li>
                    <li>{Cookies.get('id') ? <Link className="text-2xl text-white" to={'/profile'}><FaUser /></Link> : <Link className="text-white hover:text-orange-100" to={'/signup'}>تسجيل حساب</Link>}</li>
                </ul>
                <div className="visible 2md:hidden text-white text-4xl">
                    <button onClick={(e)=>setShowMenu(true)}>
                        <FiMenu/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Nav;