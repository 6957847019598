import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

const MainCards = (parms)=>{

    const [mainCards , setMainCards] = useState([])

    const content = <div className="w-full text-center">
        <div className="py-20 px-10 m-5 p-10 text-center w-full">
            <table className="border border-1px border-solid border-black w-full text-center">
                <tr className="border-b border-solid border-black">
                    <th className="w-1/4 p-5" >id</th>
                    <th className="w-1/4 p-5">image url</th>
                    <th className="w-1/4 p-5">title</th>
                    <th className="w-1/4 p-5">description</th>
                </tr>

                {mainCards.map((card , i)=>{
                    return <tr className="border-b border-solid border-black">
                        <td className="w-[20%] p-5">{card.id}</td>
                        <td className="w-[20%] p-5">{card.image}</td>
                        <td className="w-[20%] p-5">{card.title}</td>
                        <td className="w-[20%] p-5">{card.description}</td>
                        <td className="w-[20%] p-5"><button onClick={e=>deleteMainCard(card.id)}>حذف</button></td>
                    </tr >
                })}
                
            </table>
        </div>
    </div>


    const getMainCards = async()=>{
        const response = await axios.get('/api/mainCards');
        setMainCards(response.data);
    }

    const deleteMainCard = async (id)=>{
        const response = await axios.delete(`/api/mainCards/deleteMainCard/${id}` , {
            headers: {
                "Authorization":`Bearer ${Cookies.get(parms.pubkey)}`
            }
        });
        
        getMainCards();
    }

    useEffect(()=>{
       const getMainCards = async()=>{
            const response = await axios.get('/api/mainCards');
            setMainCards(response.data);
       }

       getMainCards()
    } , [parms])

    return <div id="contentContainer">{content}</div>
}

export default MainCards ;